<!-- 
 * @Author: 张阳帅
 * @Date: 2024-09-26 17:52:37
 * @LastEditTime: 2024-10-28 13:22:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\sever\appointDetail.vue
-->

<template>
  <div class="page-wrap">
    <div class="appointDetail">
      <!-- type=1 预约 type=2挂号 -->
      <div v-if="type=='1'" class="appointment-head" :class="newappointmentStatus ? '' : 'fail'">
        <template v-if="newappointmentStatus">
          <div class="subject">
            <img class="img" :src="require('../../../assets/img/sucess_icon.png')">
            {{ detail.yyztmc }}
          </div>
          <!-- <div>
                预约成功，请在就诊日当天“我的”-“我的预约”中支付
                <br>挂号费用，取消预约请在就诊前一日24点前操作。
              </div> -->
        </template>

        <template v-else>
          <div class="subject">
            <img class="img" :src="require('../../../assets/img/fail_icon.png')">
            {{ detail.yyztmc }}
          </div>
          <div v-if="detail.qxyy">
            取消原因：{{ detail.qxyy }}
          </div>
        </template>
      </div>

      <div v-else class="appointment-head">
        <!-- <div class="subject">
          <img class="img" :src="require('../../../assets/img/sucess_icon.png')">
          挂号序号：{{ detail.yyxh }}
        </div> -->
        <div>{{ detail.ddztmc }}</div>
      </div>
      <div class="appointmentDetail-body">
        <div class="info">
          <!-- <div v-if="type=='1'" class="info-number">
            预约序号：{{ detail.hyxh }}
          </div> -->
          <div class="info-item">
            <span class="info-item-title">就诊人</span>
            <div class="con">
              <!-- <span class="name"></span> -->
              {{ detail.jzrxm }} {{ detail.jzrxbmc }} {{ detail.age }}岁
              <!-- {{ detail.jzkh }} -->
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">医生姓名</span>
            <div class="con">
              {{ detail.ysxm }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊科室</span>
            <div class="con">
              {{ detail.ksmc }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊日期</span>
            <div class="con">
              {{ detail.hyrq }} {{ detail.sxwbzmc }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊时段</span>
            <div class="con">
              {{ detail.fskssj }}-{{ detail.fsjssj }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">挂号费用</span>
            <div class="con">
              <span :class="appointmentStatus ? 'price' : ''">¥ {{ detail.ghf }}</span>
            </div>
            <!-- <div class="payment-tips">
                            <span>当前平台仅支持自费就诊，医保暂未开通，无法支付</span>
                        </div> -->
          </div>
          <div class="info-item">
            <span class="info-item-title">提交日期</span>
            <div class="con">
              {{ detail.createTime }}
            </div>
          </div>
        </div>
      </div>
      <van-dialog v-model="show" :title="'请选择取消原因'" show-confirm-button show-cancel-button theme="round-button" confirm-button-text="确定" cancel-button-text="取消" confirm-button-color="#3A8AE5" cancel-button-color="#DDDDDD" @confirm="clickConfirm">
        <div class="reason-list">
          <div v-for="(v,index) in cancelContent" :id="v.id" :key="v.id" class="reason-item" :data-name="v.name" :data-current="index" :class="tabIndex==index ? 'active' : ''" @click="cancelChoose">
            {{ v.name }}
          </div>
        </div>
      </van-dialog>
      <van-dialog v-model="isCancelVisible" :title="'提示'" show-confirm-button show-cancel-button theme="round-button" confirm-button-text="确定" cancel-button-text="取消" confirm-button-color="#3A8AE5" cancel-button-color="#DDDDDD" @confirm="sure" @cancel="closeModel">
        <div class="reason-list">
          {{ cancelMsg }}
        </div>
      </van-dialog>
    </div>
    <div v-if="detail.canPay" class="modal-foot">
      <div v-if="detail.canPay" class="btn btn-primary" @click="debouncePaygoPage">
        去支付
      </div>
    </div>
    <!-- <div v-if="newappointmentStatus" class="modal-foot">
            <div class="btn btn-default" @click="cancelsChoose">
                取消预约
            </div>
            <div v-if="payStaus" class="btn btn-primary" @click="paygoPage">
                去支付
            </div>
            <div v-else class="btn btn-primary" @click="goback">
                返回首页
            </div>
        </div>
        <div v-else class="modal-foot">
            <div class="btn btn-primary" @click="goback">
                返回首页
            </div>
        </div>
        <div v-if="typeStaus" class="modal-foot">
            <div v-if="payStaus" class="btn btn-primary" @click="paygoPage">
                去支付
            </div>
            <div v-else class="btn btn-primary" @click="goback">
                返回首页
            </div>
        </div> -->
  </div>
</template>
<script>
import { addDate, debounce } from '../../../utils/index'
import { gjcModel } from '../../../api/gjcApi'
import { Toast } from 'vant'

export default {
    data(){
        return{
            id:"",
            type: '1',
            detail:{},
            ill:'',
            newappointmentStatus: true,
            isCancelVisible:false,
            typeStaus:false,
            appointmentStatus: true, // 预约状态： false->已取消
            show:false,
            payStaus:false,
            tabIndex: null,
            qxyy:"",
            errorName:'',
            cancelMsg:'',
            cancelContent:[
                {
                    name: '临时有事',
                    id: 'nsys'
                },
                {
                    name: '重复预约',
                    id: 'cfyy'
                },
                {
                    name:'不想去了',
                    id:'bxql'
                },
                {
                    name:'约错时间',
                    id:'ycsj'
                },
                {
                    name:"约错科室",
                    id:'ycks'
                },
                {
                    name:"约错医生",
                    id:"ycys"
                }
            ]
        }
    },
    mounted(){
        this.id=this.$route.query.id
        this.getDetail()
    },
    methods:{
        goback(){
            this.$router.push({
                path:'/'
            })
        },
        getDetail(){
            gjcModel.getQueryAppointRecordDetailData(this.id).then(res=>{
                this.detail = res.data       
                if(res.data.hyrq==addDate(new Date(),0 )){
                    this.payStaus=true
                }
                //预约状态名称 -1 取消预约(未支付) -2已取消(支付后取消) -3缴费超时 0已预约挂号 1已支付 2已取号 3已就诊
                if(res.data.yyzt==='-1' || res.data.yyzt==='-2' || res.data.yyzt==='-3' || res.data.yyzt==='-4'   ){
                    this.newappointmentStatus = false
                    this.typeStaus = false
                    this.appointmentStatus = false
                    //this.$store.commit(`header/${SET_HEADER_CONFIG}`, { title:'取消预约'})
                }
                if(res.data.state==='2'){
                    this.appointmentStatus = false
                    this.typeStaus = true
                }
            })
        },
        clickConfirm(){
            if(this.errorName==''){
                this.$toast('请选择原因')
                return
            }
            gjcModel.getCancelRegisterData({ qxyy: this.qxyy, appointRecordId: this.id }).then(() => {
                this.$toast("申请已提交!")
                this.getDetail()
            })
            this.isCancelAppointVisible = false
            this.newappointmentStatus = false
            this.appointmentStatus = false
            this.typeStaus = false
        },
        cancelChoose(e){
            let index = e.target.dataset.current || e.currentTarget.dataset.current
            this.errorName=e.target.dataset.name
            this.tabIndex=index
        },
        async cancelsChoose(){
            if(this.detail.hyrq<addDate(new Date(),0 )){
                this.$toast('不能取消预约!')
            }else{
                // const {data}=await appointModel.countCancel({appointId:this.id})
                // this.isCancelVisible=true
                // this.cancelMsg=data.message
                this.show=true
            }
        },
        cancelSure(){
            this.$toast('取消确定!')
        },
        sure(){
            this.isCancelVisible=false
            this.show=true
        },
        closeModel(){
            this.isCancelVisible=false
        },
        debouncePaygoPage:debounce(function(){
            this.paygoPage()
        },500,true),
        // 去支付页面
        paygoPage(){
          Toast.loading({
            mask: true,
            duration: 0,
            message: "加载中..."
          })
           gjcModel.getAppointOrderCreateData(this.id).then(res=>
           {
            Toast.clear()
              if (res.code === 200) {
                this.$router.push({
                  path:'/orderPay',
                  query:{
                      id:res.data.orderId,
                      type:'13'
                  }
                })  
              }  
          }).catch(()=>{
            Toast.clear()
          }) 
        }
    }
}
</script>
<style scoped lang='less'>
.payment-tips{
    text-align: right;
    width: 686px;
    box-sizing: border-box;
    margin: 0 auto 0;
    border-radius: 12px;
    color: #f56c6c;
    font-size: 22px;
}
div {
    box-sizing: border-box;
}
.page-wrap {
    padding-bottom: 108px;
    .orderpay_page{
        background-color: #f1f1f1;
    }
}
.appointDetail {
    padding: 0 32px;
    overflow: scroll;
    .appointment-head {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 202px;
        padding: 40px;
        line-height: 33px;
        font-size: 26px;
        color: #fff;
        text-align: center;
        background: #1c9cf6;

        &.fail {
            background: #b5b5b5;
        }

        .subject {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 33px;
          font-size: 32px;
          line-height: 40px;
  
        .img {
          width: 44px;
          height: 44px;
          margin-right: 16px;
        }
      }
    }
    .appointmentDetail-body {
      position: relative;
      z-index: 2;
      padding: 153px 0px 0px;
  
      .info {
        padding: 40px 32px;
        border-radius: 20px;
        background: #fff;
  
        .info-number {
          margin-bottom: 40px;
          font-weight: bold;
          font-size: 28px;
          color: #3d3d3d;
          text-align: center;
        }
  
        .info-item {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 20px 0;
          line-height: 40px;
          font-size: 28px;
          color: #3d3d3d;
          &.info-item__tip{
            padding-bottom: 0;
          }
  
          &.info-item-s {
            display: block;
  
            .label {
              margin-bottom: 24px;
              font-weight: bold;
            }
            .upImage {
              width: 120px;
              height: 120px;
            }
          }
  
          .info-item-title {
            font-weight: bold;
          }
  
          .con {
            flex: 1;
            color: #666;
            text-align: right;
  
            .name {
              margin-right: 24px;
            }
  
            .price {
              color: #dc3030;
            }
          }
  
          .cons {
            flex: 1;
            color: #666;
            text-align: right;
            width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
  
          .ill-desc {
            line-height: 33px;
            font-size: 26px;
            color: #999;
          }
        }
  
        .info-item-upload {
          align-items: flex-start;
  
          .con {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
  
          .pic,
          .more {
            width: 120px;
            height: 120px;
            border-radius: 8px;
          }
  
          .pic {
            margin-right: 55px;
            background: #ccc;
          }
  
          .more {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #eee;
  
            .img {
              width: 34px;
              height: 6px;
            }
          }
        }
      }
    }
  
    .reason-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0px 32px;
      margin: 40px 0;
      .reason-item {
        min-width: 160px;
        height: 52px;
        margin: 12px 22px;
        line-height: 52px;
        font-size: 28px;
        color: #666;
        text-align: center;
        background: #f2f2f2;
  
        &.active {
          color: #fff;
          background: #1c9cf6;
        }
      }
    }
    .van-dialog {
      width: 686px;
    }
    /deep/.van-dialog--round-button .van-dialog__confirm {
    }
    /deep/.van-dialog--round-button .van-dialog__cancel {
    }
    /deep/.van-goods-action-button--first {
      border-radius: 12px;
    }
    /deep/.van-goods-action-button--last {
      border-radius: 12px;
    }
  }
  .modal-foot {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 98px;
    line-height: 98px;
    background: #f2f2f2;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 999;
    .btn {
      width: 322px;
      height: 68px;
      line-height: 68px;
      font-size: 30px;
      text-align: center;
      border-radius: 12px;
    }
    .btn-default {
      color: #666;
      background: #ddd;
    }
    .btn-primary {
      color: #fff;
      background: #1c9cf6;
    }
  }
</style>
